export const urlBuilder = (appName: keyof typeof config, config: any) => {
    //handling localhost or other usecases where port is also included in url
    // include the values before and after the first colon only (ex. http:localhost:3000 )
    let baseUrl = window.location.origin
        .split(":")
        .filter((_, i) => i <= 1)
        .join(":");

    let tmp = config[appName];
    return `${baseUrl}:${tmp.port}${tmp.custom ? `/${tmp.custom}` : ""}`
}