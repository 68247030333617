import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Redirect from "./common/Redirect";
import Header from "./Header";
import Home from "./Home";
import Stocks from "./Stocks";
import Todo from "./Todo";
import Work from "./Work";
import Media from "./Media";
import config from "./serverConfig.json";
import { urlBuilder } from "./common/utils";
import './App.css';


const client = new ApolloClient({
  uri: "https://leetcode.com/graphql",
  cache: new InMemoryCache()
});

type configKeyType = keyof typeof config;

// color theme https://tailwind.simeongriggs.dev/red/0D9CEE
function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="home" element={<Home />} />
            <Route path="stocks" element={<Stocks />} />
            <Route path="todo" element={<Todo />} />
            <Route path="work" element={<Work />} />
            <Route path="media" element={<Media />} />
            {Object.keys(config).map((key) => {
              return (
                <Route key={key} path={key} element={<Redirect url={urlBuilder(key as configKeyType, config)}/>}/>
              )
            })}
            {/* default route */}
            <Route path="*" element={<Navigate to="/media" replace />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
}

export default App;
