export default function Todo() {
    return (
        <>
            GENERAL:
            <ul>
                <li>1.  Setup reverse proxy server so I don't have to manually port forward every port</li>
                
                <li>2. https setup? </li>
                
            </ul>
            
            <br /><br /><br />
            STOCK WEBAPP:
            <ul>
                <li>Edit here: http://jangfamily.local:3000/e/en/stonks-todo</li>
            </ul>

            <br /><br /><br />
            PERSONAL:
            <ul>
                <li>1. Notes server: trilium? </li>
                <li>2. Manga tagger</li>
                <li>3.http://jangfamily.local:3000/en/selfhosted-todo  </li>
            </ul>
        </>
    )
}