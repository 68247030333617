import { useEffect }from "react";

export default function Redirect(props: {url: string}) {
    const { url } = props;

    useEffect(() => {
        window.location.href = url;
    }, []);

    return (
        <div>
            Redirecting...
        </div>
    )
}
