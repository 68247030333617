import { useState } from "react";
import config from  "../serverConfig.json";
import { urlBuilder } from "../common/utils";

const unlockKey = '19';

export default function Media() {
    const [isPageUnlocked, setIsPageUnlocked] = useState(false);
    const [inputValue, setInputValue] = useState("");
    
    const onMouseEnter = (e: any) => {
        e.stopPropagation();
        e.target.className = "text-blue-600";
    };
    const onMouseLeave = (e: any) => {
        e.stopPropagation();
        e.target.className = "text-blue-500";
    };

    const onFormSubmit = (e: any) => {
        e.preventDefault();
        inputValue === unlockKey && setIsPageUnlocked(true) 
        setInputValue("");
    }

    const displayPrivateListItem = (i: number) => {
        return <li key={i}>
            This item is locked.
        </li>
    }

    const unlockLinksForm = () => {
        return (
            <span className="flex justify-center">
                <form className="w-full max-w-sm" onSubmit={onFormSubmit}>
                    <div className="flex items-center border-b border-blue-500 py-2">
                        <input onChange={e => setInputValue(e.target.value)} value={inputValue} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Unlock" aria-label="Unlock" />
                        <input className="flex-shrink-0 bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded" type="submit"/>                            
                    </div>
                </form>
            </span>
        );
    }

    return (
        <>
            {unlockLinksForm()}
            <br/>
            <ul className = "list-disc">
                {Object.entries(config).map(([key, value], index: number) => {
                    return (value.private && !isPageUnlocked) 
                        ? displayPrivateListItem(index) 
                        : (
                            <li key={index}>
                                <span>
                                    <img className="icon" alt="alt" src={value.iconSrc} />
                                    <a className="text-blue-500" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} href={urlBuilder(key as keyof typeof config, config)}> {key}</a><p>Description: {value.description}</p>
                                </span>
                            </li>
                    )
                })}
            </ul>
        </>
    )
}