import React from "react";
import { useQuery, gql } from "@apollo/client";

const LEETCODE_QUERY = gql`
  { 
    matchedUser(username: "davidjang93") {
        username
        submitStats: submitStatsGlobal {
            acSubmissionNum {
                difficulty
                count
                submissions
            }
        }
    }
}`;

export default function WorkPage() {
    // const { data, loading, error } = useQuery(LEETCODE_QUERY);
    
    // type difficultySolvedResponse = {
    //     difficulty: "All" | "Easy" | "Medium" | "Hard", 
    //     count: number, 
    //     submission: number
    // };

    // let problemsSolved = {
    //     "All": {} as difficultySolvedResponse,
    //     "Easy": {} as difficultySolvedResponse,
    //     "Medium": {} as difficultySolvedResponse,
    //     "Hard": {} as difficultySolvedResponse
    // } ;


    // if (loading) return <span>"Loading data"</span>;
    // if (error) return <pre>{error.message}</pre>

    // if (data) {
    //     data.matchedUser.submitStats.acSubmissionNum.forEach((solvedData: difficultySolvedResponse) => {
    //         problemsSolved[solvedData.difficulty] = solvedData
    //     })
    // }
    return (
        <div>
            <h1>David has solved a total # of problems: </h1>
            {/* <ul>
                <li>Easy: solved: {problemsSolved["Easy"].count}, attempted: {problemsSolved["Easy"].submission}</li>
                <li>Medium: solved: {problemsSolved["Medium"].count}, attempted: {problemsSolved["Medium"].submission}</li>
                <li>Hard: solved: {problemsSolved["Hard"].count}, attempted: {problemsSolved["Hard"].submission}</li>
            </ul> */}
            <iframe src="https://leetcard.jacoblin.cool/davidjang93?theme=dark&font=Autour%20One&ext=activity" title="Leetcode widget"/>
        </div>
    )


}